@import '../../constants';
@import '../../common';

.close {
  @extend %thin-border;
  @extend %main-security;

  text-align: left;

  h2 {
    margin-bottom: 1.5rem;
  }

  .warning {
    border: 1px solid;
    border-color: $color-error;
    background-color: $color-error-background;
    border-radius: 0.25rem;
    padding-left: 2rem;
    padding-right: 2rem;
    margin: 2rem;
  }
}

.modalDisplay {
  margin: 0;
  border: none;
  padding: 0 0 1rem 0;
}
