@import '../constants';
@import '../mixins';

.label {
  display: flex;
  align-items: center;
}

.slider {
  position: relative;
  margin-left: 1rem;
  background-color: $color-gray;
  cursor: pointer;

  border: none;

  &:focus {
    box-shadow: 0 0 2px 1px $color-primary-hover;
  }

  height: 1.25rem;
  width: 2.5rem;
  border-radius: 0.625rem;

  .span:after {
    position: absolute;
    content: '';
    background-color: $color-white;
    transition: left ease-in-out 0.3s, background-color ease-in-out 0.1s;

    left: 0.25rem;
    top: 0.1875rem;
    width: 0.875rem;
    height: 0.875rem;
    border-radius: 0.4375rem;
  }
}

.slider[aria-checked='true'] {
  background-color: $color-primary;
  .span:after {
    left: 1.3875rem;
  }
}
