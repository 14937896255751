@import '../constants';
@import '../common';

.nav {
  padding: 0.75rem 0.1875rem;
  background-color: $color-darkwhite;

  @include medium {
    flex: 0 0 auto;
    background-color: inherit;
    padding: 1.625rem 0 0 0.8125rem;
  }

  @include large {
    padding: 2rem 0 0 1rem;
  }

  ul {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem 0.5rem;

    margin-left: 0.75rem;

    @include medium {
      margin-left: 1rem;
      flex-direction: column;
      gap: 0;
    }
  }

  li {
    @extend %font-default;

    list-style: none;
    margin: 0;
    width: fit-content;
    padding: 0 0.25rem 0.25rem;
    font-weight: $font-semibold;

    @include medium {
      margin: 1.5rem 0;
      padding-bottom: 0.75rem;
    }

    &.active {
      pointer-events: none;
      text-decoration: none;
    }
    &.active,
    &:hover,
    &:focus-within {
      border-bottom: 3px solid $color-primary;
      @include medium {
        padding-bottom: calc(0.75rem - 3px);
      }
    }

    a {
      color: black;
      text-decoration: none;
      cursor: pointer;
    }
  }

}
