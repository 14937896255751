@import '../constants';
@import '../common';

.buttonSecondary {
  @extend %button;

  border: 1px solid $color-secondary;
  background-color: $color-white;
  color: $color-secondary;

  &:hover,
  &:focus {
    box-shadow: 0 0 4px 0 $color-secondary;
  }
}
