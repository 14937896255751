@import '../../constants';
@import '../../common';

.table {
  @extend %table;

  tr > th[scope='row'] {
    div {
      height: 3rem;
      width: 3rem;
      border: 1px solid $color-gray;
      border-radius: 1.5rem;

      img {
        height: 2rem;
        width: 2rem;
        margin: calc(0.5rem - 1px);
      }
    }
  }

  .none {
    text-align: center;
  }

  td.login {
    word-break: break-word;

    > :first-child {
      @extend %font-medium;

      color: $color-secondary;
    }
    > :nth-child(2) {
      font-weight: $font-semibold;
    }
  }
}

td.hide {
  display: none;
}

td.remove {
  &:before {
    display: none;
  }

  @media screen and (max-width: $medium) {
    padding: 0.5rem;
  }

  button {
    @extend %link;
    display: block;
    margin: 0 auto;
    border: none;
    background-color: inherit;
    color: $color-error;
    cursor: pointer;

    img {
      display: inline-block;
      height: 1.5rem;
      width: auto;
      vertical-align: middle;
      margin-right: 0.5rem;
    }

    &:hover,
    &:focus {
      color: $color-error-hover;
    }
  }
}

.apple {
  padding-bottom: 0.1875rem;
}
