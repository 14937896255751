@import '../../constants';
@import '../../common';

.logins {
  @extend %thin-border;
  @extend %main-security;
}

.title {
  margin-bottom: 1rem;

  img {
    @extend %margin-right-medium;

    height: 1.5rem;
    width: auto;
  }
}

.subtitle {
  margin-top: 2rem;
  margin-bottom: 1rem;
  padding-bottom: 1rem;

  border-bottom: 1px solid $color-lightgray;
}

.inactive {
  display: flex;
  flex-flow: row wrap;
}

.inactiveItem {
  @extend %margin-right-medium;

  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  padding: 0.375rem 0.75rem;
  border: 1px solid $color-gray;
  border-radius: 1.5rem;

  color: inherit;
  font-weight: $font-semibold;
  text-decoration: none;

  @include medium {
    padding: 0.42rem 0.8125rem;
  }

  @include large {
    padding: 0.5rem 1rem;
  }

  &:hover,
  &:active,
  &:focus {
    border-color: $color-black;
    text-decoration: underline;
  }

  img {
    @extend %margin-right-medium;

    height: 2rem;
    width: auto;
  }
}
