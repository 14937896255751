@import '../constants';
@import '../common';

.content {
  @extend %main-background;

  padding: 1rem 0 0 0;

  @include small {
    padding: 1rem;
  }

  @include large {
    padding: 3rem 1rem;
  }

  display: grid;
  place-items: center;
}
