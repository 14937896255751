@import '../../mixins';

.errorWrapper {
  margin-top: 1rem;
  border: 1px solid $color-lightgray;
  padding: 0 1rem;

  @include small {
    margin: 1rem 1rem 0;
  }

  .error {
    color: $color-error;
    margin-bottom: 0.5rem;
  }

  .small {
    margin-top: 0.5rem;
  }
}
