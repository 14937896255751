@import '../constants';
@import '../mixins';

.label {
  display: block;
  position: relative;
  cursor: pointer;

  &:hover {
    input ~ .radio {
      background-color: $color-gray;
    }
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked ~ .radio {
      border: none;
      background-color: $color-primary;

      &:after {
        display: block;
      }
    }

    &:checked:focus ~ .radio {
      box-shadow: 0 0 2px 1px $color-primary-hover;
    }
  }

  .radio {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: $color-white;
    border: 1px solid $color-gray;
    border-radius: 50%;

    &:after {
      content: '';
      position: absolute;
      display: none;

      top: 6px;
      left: 6px;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: $color-white;
    }
  }
}

.children {
  margin-left: 2rem;
}
