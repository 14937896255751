@import '../../constants';
@import '../../common';

.qr {
  display: block;
  margin: 1rem auto 0;

  figcaption {
    @extend %font-default;

    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: $font-semibold;

    > img {
      height: 1.25rem;
    }
  }

  > img {
    margin: 0;
    width: 100%;
    height: auto;
  }

  a {
    @extend %link;
    @extend %font-small;

    display: block;
    margin: 0.5rem auto 0;
    text-align: center;
  }
}
