@import '../constants';
@import '../common';

.top {
  margin-bottom: 6rem;

  img {
    display: block;
    margin: 2rem auto;
    height: 2rem;
    width: auto;
  }
}

.bottom {
  background-color: $color-lightgray;
  min-height: calc(100vh - 8rem);

  .content {
    @extend %thin-border;
    @extend %padding-huge;

    transform: translateY(-4rem);
    margin: 0 auto;
    max-width: 32rem;
    background-color: $color-white;
    box-shadow: 0 0 10px 1px $color-gray;
    text-align: center;
  }

  h2 {
    text-transform: uppercase;
    margin-bottom: 2rem;
  }

  a {
    @extend %link;
  }
}
