@import '../constants';
@import '../common';

.indicatesRequiredField {
  font-size: 0.875rem;
  font-weight: $font-semibold;
  color: $color-dark-text;
  margin-bottom: 0.3125rem;
  line-height: 1.3125rem;
  text-align: left;
}
