@import '../common';

.wrapper {
  @extend %main-background;
  @extend %side-nav-wrapper;
}

.content {
  @extend %main-background;

  @include medium {
    padding: 1rem calc(50vw - 30rem);
  }

  @include large {
    padding: 3rem calc(50vw - 37.5rem);

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}
