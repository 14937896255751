@import '../constants';
@import '../common';
@import '../mixins';

.body {
  text-align: left;
}

.background {
  font-weight: $font-bold;
  padding: 1rem;
  background: $color-secondary-background;
}

.form {
  margin-top: 2rem;
  text-align: left;

  label {
    margin-bottom: 2rem;
    display: flex;
    align-items: center;

    // change the default checkbox styles
    // if this needs to get re-used, maybe make a new larger checkbox?
    div:nth-child(2) {
      position: relative;
      flex: 0 0 auto;
      width: 2rem;
      height: 2rem;

      &::after {
        left: 0.75rem;
        top: 0.35rem;
        width: 0.5rem;
        height: 1rem;
        border-width: 0 0.25rem 0.25rem 0;
      }
    }
    div:nth-child(3) {
      margin-left: 1rem;
      font-weight: $font-bold;
    }
  }

  button {
    @extend %font-default;

    width: 100%;
    padding: 1rem;
  }
}
