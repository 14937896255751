@import '../../constants';
@import '../../common';

.warning {
  text-align: left;
  border: 1px solid;
  border-color: $color-warning;
  background-color: $color-warning-background;
  border-radius: 8px;
  margin: 1.5rem 0rem;
  border-left: 8px solid $color-warning;
  display: flex;
  padding: 1rem 1rem 1rem 1.5rem;
  gap: 0.75rem;

  h3 {
    font-size: 1rem;
    margin-bottom: 0.75rem;
  }
  p { 
    margin: 0;
  }
}

.background {
  background: $color-secondary-background;
  padding: 1rem;
}
