@import '../../common';
@import '../../constants';

.accounts {
  @extend %thin-border;
  @extend %main-security;
}

.title {
  @extend %card-title;

  :first-child {
    height: 1.5rem;
    width: auto;
    @extend %margin-right-medium;
    vertical-align: bottom;
  }
}

.body {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.actions {
  :first-child {
    padding-right: 1rem;
  }
}

.table {
  @extend %table;
  margin-bottom: 0;

  .bold {
    font-weight: $font-semibold;
  }

  tr > td.none {
    text-align: center;
  }

  tr > th {
    width: 33%;
  }

  tr {
    border-bottom: 1px solid $color-lightgray;
    padding-left: 0;
  }

  tr > td > div.bold {
    padding-bottom: 0.5rem;
  }
}
