@import '../../constants';
@import '../../common';
@import '../../mixins';

%twofarow {
  @extend %padding-large;

  margin-left: 1rem;
  margin-bottom: 1rem;
  border: 1px solid $color-gray;

  background-color: $color-white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.rowActive {
  @extend %twofarow;

  border-left: 0.5rem solid $color-secondary;

  .left {
    @extend %margin-right-medium;

    min-width: 10rem;

    .name {
      color: $color-secondary;
      font-weight: $font-semibold;
      margin-bottom: 0.5rem;
    }
  }

  .center {
    margin-top: 1rem;

    @include small {
      display: flex;
      flex-flow: row wrap;
      flex: 1 1 auto;
      margin-top: 1rem;
    }

    @include medium {
      margin-top: 0;
    }

    .pill {
      display: flex;
      border: 1px solid $color-primary;
      border-radius: 1.5rem;
      color: $color-primary;
      font-weight: $font-semibold;
      align-items: center;
      margin: 0 0.5rem 0rem 1rem;

      span {
        @extend %margin-right-medium;

        margin-left: 1rem;
      }
    }
  }

  .right {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    margin-left: auto;
  }
}

.rowEdit {
  @extend %twofarow;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;

  .right {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
  }
}
