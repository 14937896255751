@import '../../constants';
@import '../../common';

.activity {
  @extend %padding-large;
  @extend %thin-border;

  flex: 1;
  margin-bottom: 1rem;
  background-color: $color-white;

  a {
    @extend %link;
  }
}

.title {
  @extend %padding-medium;

  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;

  img {
    @extend %margin-right-medium;

    border-radius: 1rem;
    height: 2rem;
    width: auto;
    vertical-align: bottom;
  }
}

.signin {
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-bottom: 1rem;

  .description {
    text-align: center;
    margin-bottom: 0.25rem;
  }
}

.manage {
  margin: 0 auto;
  text-align: center;
}
