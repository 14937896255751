@import '../../constants';
@import '../../common';



.inputGroup {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.fullWidth {
  width: 100%;
  float: left;
}
