@import '../../constants';
@import '../../common';

.ids {
  @extend %thin-border-top;

  padding: 1.5rem 1.5rem 0;

  @include medium {
    padding: 1.625rem 1.625rem 0;
  }

  @include large {
    padding: 2rem 2rem 0;
  }

  grid-area: ids;
  background-color: $color-white;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  a {
    @extend %link;
  }
}

.title {
  padding: 0.75rem 0.75rem 0;

  @include medium {
    padding: 0.8125rem 0.8125rem 0;
  }

  @include large {
    padding: 1rem 1rem 0;
  }

  display: flex;
  justify-content: space-between;

  h3 {
    display: flex;
    align-items: center;
  }

  img {
    @extend %margin-right-medium;

    width: auto;
    vertical-align: bottom;

    max-height: 1.5rem;

    @include medium {
      max-height: 1.625rem;
    }

    @include large {
      max-height: 2rem;
    }
  }
}

.counter {
  grid-area: id-counter;
  padding-top: 1rem;
  text-align: center;

  div {
    display: inline-block;
    margin: 0 auto;
  }
}

.no {
  @extend %padding-medium;

  text-align: center;
  border-bottom: 1px solid $color-lightgray;

  h4 {
    color: $color-secondary;

    @include medium {
      margin-top: 2rem;
    }
  }

  p {
    padding: 1rem;

    @include medium {
      padding: 1rem 2rem;
    }
  }
}

.list {
  border-bottom: 1px solid $color-lightgray;
  padding: 0.75rem 0.75rem 1.5rem;

  @include small {
    display: flex;
    justify-content: space-between;
  }

  @include medium {
    padding: 0.8125rem 0.8125rem 1.625rem;
  }

  @include large {
    padding: 1rem 1rem 2rem;
  }

  ul {
    flex: 1 1 auto;

    @include small {
      margin-right: 2rem;
    }
  }

  li {
    list-style-type: none;
  }
}

.manage {
  text-align: center;
  align-self: flex-end;

  margin-top: 1rem;

  @include small {
    margin-top: 0;
  }
}

.idCard {
  margin-top: 0.5rem;

  button {
    width: 100%;
    padding: 0;
    border: none;
    border-top-left-radius: 1.25rem;
    border-top-right-radius: 1.25rem;
  }
}

.cardBottom {
  @extend %padding-medium;

  display: flex;

  border-bottom-left-radius: 1.25rem;
  border-bottom-right-radius: 1.25rem;
  border-left: 1px solid $color-lightgray;
  border-right: 1px solid $color-lightgray;
  border-bottom: 1px solid $color-lightgray;
}

.cardDescription {
  margin: 0 0.25rem;
  flex: 1 1 auto;
}
.cardDate {
  margin: 0 0.25rem;
  flex: 0 1 auto;

  :first-child {
    color: $color-primary;
    font-weight: $font-bold;
  }

  .expired {
    color: $color-error;
  }
}
