@import '../../constants';
@import '../../common';

.header {
  padding: 2.5rem 2.5rem 2rem 2.5rem;

  width: 100%;

  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.name {
  @extend %font-hero;
}

.memberSince {
  font-weight: $font-semibold;
}
