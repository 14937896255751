@import '../constants';
@import '../common';

.table {
  @extend %table;

  th,
  .noWrap {
    white-space: nowrap;
  }

  .wordBreakBold {
    word-break: break-word;
    font-weight: $font-semibold;
  }

  .bold {
    font-weight: $font-semibold;
  }

  tr > td.none {
    text-align: center;
  }

  tr > td > div.bold {
    padding-bottom: 0.5rem;
  }
}
