@import '../../constants';
@import '../../common';

%choice {
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;

  flex: 1 1 33.33%;
  padding: 1rem;

  &:not(:last-child) {
    border-bottom: 1px solid $color-lightgray;
  }

  @include small {
    @include thin-border;
    background-color: $color-darkwhite;

    .description {
      flex: auto;
    }
  }

  @include medium {
    padding: 1.5rem;
  }

  @include large {
    padding: 2.5rem;
  }

  h4 {
    font-size: $font-medium;
  }
}

.title {
  @extend %card-title;
}

.choices {
  @include small {
    display: flex;
    flex-flow: row nowrap;
    align-items: stretch;
    column-gap: 1rem;
  }
  background-color: $color-white;
}

.choice {
  @extend %choice;
}
