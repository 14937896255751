@import '../constants';
@import '../common';
@import '../mixins';

.footer {
  border-top: 1px solid $color-lightgray;
}

.content {
  @extend %padding-large;

  max-width: 75rem;
  margin: 0 auto;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;

  ul {
    position: relative;
    list-style: none;
  }

  li {
    margin-bottom: 1rem;
    line-height: 1.5rem;

    a {
      color: $color-dark-text;
      text-decoration: none;
      font-weight: $font-normal;

      &:active,
      &:focus,
      &:hover {
        color: $color-windbreaker;
      }
    }
  }

  .title {
    position: relative;
    margin: 0 0 1rem 0;
    font-weight: $font-semibold;
  }
}

.footerNav {
  display: flex;
  justify-content: space-between;
  flex-flow: row wrap;
  flex: 0 0 100%;
  margin: 1.25rem 0;

  @include medium {
    justify-content: flex-end;
    margin: 1.25rem auto;
    flex: 0 0 45rem;
  }

  @include large {
    flex: 0 0 50%;
    justify-content: space-between;
  }
}

.social {
  padding: 0;
  flex: 0 0 100%;

  @include medium {
    text-align: center;
    padding: 1rem 0;
  }

  @include large {
    flex: 0 0 25%;
    text-align: left;
  }

  .logo {
    width: 7.5rem;
    height: 2.5rem;
  }

  .social {
    display: inline-flex;
    align-items: center;

    .iconSocial {
      margin: 0 1.5rem 2.5rem 0;
    }
    .iconTwitter {
      width: 3rem;
      height: 1.5rem;
    }
    .iconFacebook {
      width: 3rem;
      height: 1.5rem;
    }
    .iconLinkedin {
      width: 3rem;
      height: 3rem;
    }
    .iconInstagram {
      margin-left: 0.75rem;
      width: 1.75rem;
      height: 1.75rem;
    }
  }
}

.cta {
  display: inline-flex;
  height: 20%;
  flex: 0 0 100%;
  white-space: pre;

  @include large {
    flex: 0 0 15%;
  }

  .support {
    @extend %font-default;
    @extend %padding-medium;

    margin: 1.25rem auto;
    width: 100%;
    border-radius: 3px;
    font-weight: $font-semibold;
    color: $color-white;
    background: $color-primary-visited;
    text-decoration: none;

    @include medium {
      width: auto;
    }

    &:focus,
    &:hover,
    &:focus {
      background-color: $color-windbreaker;
    }
  }
  .arrow {
    padding-left: 15px;
  }
}

.discover,
.learn,
.connect {
  flex: 0 0 50%;
  padding: 1.25 0;

  @include medium {
    flex: 0 0 30%;
  }

  @include large {
    padding: 0;
  }

  .columns {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
  }
}

.legal {
  @extend %padding-large;

  max-width: 75rem;
  margin: 0 auto;
  color: $color-gray;

  ul {
    display: flex;
    flex-flow: row wrap;

    @include medium {
      margin-left: 2rem;
      display: inline-flex;
    }

    li {
      @extend %margin-right-medium;

      > a {
        text-decoration: none;
        color: $color-gray;

        &:hover {
          color: $color-lightblack;
        }
      }
    }
  }
}
