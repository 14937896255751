@import '../../constants';
@import '../../common';

.email {
  @extend %font-medium;

  margin: 0 2rem;
  padding: 0 0 2rem;
  border-bottom: 1px solid $color-lightgray;

  font-weight: $font-semibold;
  color: $color-secondary;
  width: calc(100% - 4rem);
  word-wrap: break-word;
}
