@import '../../constants';
@import '../../common';
@import '../../mixins';

ul.addressSuggestions {
  background-color: $color-white;
  border: 1px solid $color-secondary;
  border-radius: 5px;
  list-style: none;
  overflow: hidden;
  width: 100%;
  margin-bottom: 1rem;
  text-align: left;

  li {
    color: $color-dark-text;
    cursor: pointer;
    margin: 0;
    padding: 1rem 0.625rem;

    &:not(:last-child) {
      border-bottom: 1px solid $color-secondary;
    }

    &:hover, &.highlighted {
      background-color: $color-darkwhite;
      color: $color-gothic;
    }

    .suggestionArea {
      // Needs to be darkened to meet WCAG
      color: #5e5e5e;
    }
  }
}

