@import '../../constants';
@import '../../common';

.wrapper {
  @extend %main-security;
}

%row-wrapper {
  margin: 2rem 0;
  border: 1px solid $color-gray;
  border-radius: 0.5rem;
  padding: 0.75rem 1.5rem 0.75rem 0.75rem;

  @include medium {
    padding: 0.8125rem 1.625rem 0.8125rem 0.8125rem;
  }

  @include large {
    padding: 1rem 2rem 1rem 1rem;
  }
}

.enrolled {
  @extend %row-wrapper;
  background-color: $color-secondary-background;
}

.unenrolled {
  @extend %row-wrapper;
  @extend %padding-large;
}

.notCompatible {
  @extend %row-wrapper;
  background-color: $color-darkwhite;
}

.deprecated {
  @extend %row-wrapper;
  background-color: $color-error-background;
  padding: 2rem 1rem 1rem 1rem;

  .error {
    color: $color-error;
    font-weight: $font-semibold;
  }
}

.row {
  padding: 1rem 0;
  display: flex;
  align-items: flex-start;

  .imgWrapper {
    @extend %margin-right-medium;

    align-self: flex-start;
    flex: 0 0 4rem;

    img {
      width: 2.5rem;
      display: block;
      margin: 0 auto;
    }
    .oldWallet {
      height: 2rem;
    }
  }

  & > div {
    flex: 1 1 auto;
  }

  h5 {
    @extend %font-medium;

    text-transform: none;
    margin-bottom: 0.5rem;

    > span {
      color: $color-primary;
    }
  }

  p {
    margin: 0;
  }

  .buttonWrapper {
    flex: 0 0 auto;
  }

  .notCompatibleMessage {
    margin-top: 0.5rem;
    font-weight: $font-semibold;
    font-style: italic;
  }

  .weak {
    @extend %font-small;

    margin-top: 0.5rem;
    font-weight: $font-semibold;
  }
}

.alert {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-left: 1rem;
  border: 1px solid $color-lightgray;
  border-radius: 0.5rem;
  padding: 2rem 1rem 1rem 1rem;
  background: $color-white;

  @include medium {
    margin: 1rem 0 1rem 5rem;
  }

  > p {
    margin-top: 0;
    padding-left: 1rem;
  }

  .buttonWrapper {
    flex: 0 0 auto;
  }
}
