@import './constants';
@import './common';
/* some comment to change hash for cache */
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video,
fieldset {
  margin: 0;
  padding: 0;
  border: 0;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
*,
*:after,
*:before {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  outline: 0;
  outline: none;
}

body {
  font-size: 1rem;

  font-family: 'Open Sans', helvetica, sans-serif;
  color: $color-dark-text;
  overflow-x: hidden;
}

p {
  margin: 1rem 0;
  line-height: 1.5;
}

h1 {
  @extend %h1;
}

h2 {
  @extend %h2;
}

h3 {
  @extend %h3;
}

h4 {
  @extend %h4;
}

h5 {
  @extend %h5;
}

h6 {
  @extend %h6;
}

ul {
  list-style-type: none;
}

ol {
  list-style-position: inside;
}

.hidden {
  display: none;
}

.sr-only {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

button {
  cursor: pointer;
}
