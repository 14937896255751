@import '../constants';
@import '../common';

.callout {
  @extend %font-medium;

  margin-top: 2rem;
  margin-bottom: 2rem;
  border-radius: 8px;

  background-color: $color-info-background;
  padding: 1.25rem 1.75rem;

  position: relative;

  p {
    margin: 0;
  }
}
