@import '../constants';
@import '../common';
@import '../mixins';

.idTitle {
  padding: 0.5625rem;

  @include medium {
    font-size: 0.8125rem;
    padding: 0.625rem;
  }

  @include large {
    padding: 0.75rem;
  }

  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  border: none;
  border-top-left-radius: 1.25rem;
  border-top-right-radius: 1.25rem;

  color: $color-white;
  text-transform: uppercase;
  text-align: left;

  font-weight: $font-bold;
  letter-spacing: $font-doublewide;

  img {
    flex: 0 0 auto;
    height: 1.5rem;
  }

  img.icon {
    @extend %margin-right-medium;

    min-width: auto;
    width: 2rem;
  }

  .title {
    flex: 1 1 auto;
    color: white;
  }

  img.indicator {
    align-self: flex-start;
    margin-left: 1rem;
  }
}
