@import '../../constants';
@import '../../common';

.wrapper {
  max-width: 72rem;
  margin-top: 1rem;

  @include small {
    margin: 1rem;
  }

  @include medium {
    margin-top: 2rem;
  }
}
