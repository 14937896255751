@import '../mixins';

.wrapper {
  display: block;
  margin: 1rem 0;
  position: relative;

  font-family: Poppins, Arial, Helvetica, sans-serif;

  select,
  input,
  textarea {
    @include input-styles;
  }

  input {
    background-color: $color-white;
  }

  select {
    appearance: none;
    background-color: $color-white;

    &:read-only {
      // I don't know why I have to do this
      background-color: white;
      cursor: unset;
    }

    &:invalid {
      color: $color-darkgray;
    }

    line-height: 1.625rem;
    font-size: 1rem;
  }

  textarea {
    min-height: 8rem;
    line-height: 1.15;
  }

  .label {
    font-size: 0.875rem;
    font-weight: $font-bold;
    color: $color-dark-text;
    margin-bottom: 0.3125rem;
    line-height: 1.3125rem;
    text-align: left;
  }

  .required {
    color: $color-lightblack;
    font-size: 0.875rem;
  }

  .caret {
    position: absolute;
    right: 1rem;
    top: 2.625rem;
    height: 0.75rem;
    pointer-events: none;
  }

  .fullWidth {
    width: 100%;
  }

  .checkboxGroup {
    padding-top: 0.25rem;
    padding-left: 0.25rem;
  }
}

.error {
  color: $color-error;
  margin: 0.25rem 0;
  font-size: $font-default;
  text-align: left;
}

.noMargin {
  margin: 0;
}
