@import './constants';

@mixin small {
  @media screen and (min-width: #{$small}) {
    @content;
  }
}

@mixin medium {
  @media screen and (min-width: #{$medium}) {
    @content;
  }
}

@mixin notLarge {
  @media screen and (max-width: #{$large - 1px}) {
    @content;
  }
}

@mixin large {
  @media screen and (min-width: #{$large}) {
    @content;
  }
}

@mixin thin-border {
  border: 1px solid $color-lightgray;
  border-radius: 0.5rem;
}

@mixin fadeOut {
  @keyframes fade {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 1;
    }
    75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }

  animation-name: fade;
  animation-duration: 4s;
}

@mixin input-styles {
  width: 100%;
  padding: 0.875rem 1rem;
  border: 1px solid $color-gray;
  border-radius: 0.5rem;
  background-color: $color-white;
  line-height: 1.625rem;

  &.errorInput {
    border-color: $color-error;
    color: $color-darkgray;
  }

  &:focus {
    outline: 2.5px solid $color-secondary;
    outline-offset: -1px;
    border-color: $color-secondary;
    background-color: $color-white;
    color: $color-dark-text;
  }

  &:hover {
    border-color: $color-dark-text;
    background-color: $color-darkwhite;
  }

  &:disabled,
  &:read-only {
    background-color: $color-darkwhite;
    color: $color-dark-text;
    cursor: not-allowed;
  }
}
