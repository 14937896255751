@import '../constants';
@import '../common';

.search {
  position: relative;

  span.srOnly {
    @extend %sr-only;
  }

  input[type='text'] {
    width: 100%;
    height: 3rem;
    border: 1px solid $color-gray;
    border-radius: 0.5rem;
    padding-left: 1rem;

    @include medium {
      width: 30rem;
      height: 2.5rem;
    }

    @include large {
      width: 30rem;
      height: 3rem;
    }
  }

  button,
  div {
    @extend %padding-small;

    position: absolute;

    top: 0.5rem;
    right: 0.5rem;
    width: 3rem;

    @include medium {
      left: 27rem;
      height: 2rem;
      width: 2.5rem;
    }
  }

  button {
    border: none;
    cursor: pointer;
  }
}
