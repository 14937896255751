@import '../../constants';
@import '../../common';

.tile {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  border: 1px solid $color-darkgray;
  border-left: 0.5rem solid $color-darkgray;
  border-radius: 0.5rem;
  position: relative;
  text-align: left;
  margin-bottom: 1rem;

  .tileBody {
    margin: 1rem 1rem 0 1rem;
    flex-basis: 100%;
    display: flex;
    align-items: flex-start;

    .icon {
      height: 2rem;
      width: 2rem;
      stroke: $color-dark-text;
    }
    .content {
      display: flex;
      flex-direction: column;
      margin-left: 1rem;
    }
    .heading {
      @extend %font-medium;

      font-weight: $font-semibold;
      margin: 0.5rem 0;
    }
    .body {
      margin: 0.5rem 0;
    }
  }
}
