@import '../constants';
@import '../common';

.toast {
  text-align: left;
  border: 1px solid;
  border-radius: 8px;
  margin: 1.5rem 0rem;
  border-left: 8px solid;
  display: flex;
  padding: 1rem 1rem 1rem 1rem;
  gap: 0.75rem;

  .content {
    margin-top: 0.1875rem;
  }
}

.info {
  border-color: $color-secondary;
  background-color: $color-secondary-background;
}

.warning {
  border-color: $color-warning;
  background-color: $color-warning-background;
}