@import '../constants';
@import '../common';
@import '../mixins';

.spacer {
  padding: 1rem;
  background-color: $color-lightblack;

  @include large {
    background-color: $color-white;
    border-top: 1px solid $color-lightgray;
    border-bottom: 1px solid $color-darkwhite;
  }
}

.navClosed {
  overflow: hidden;
}

.navOpen {
  overflow: visible;
}

.navOpen,
.navClosed {
  position: relative;
  display: flex;
  padding: 1rem 1rem;
  flex-wrap: wrap;
  border-bottom: 1px solid $color-lightgray;

  gap: 0.5rem 1rem;

  @include large {
    padding: 0 1rem 0;
  }

  .mobileTitle {
    @extend %font-medium;

    display: flex;
    justify-content: space-between;

    flex: 1 1 auto;
    background-color: $color-white;
    color: $color-lightblack;

    padding: 0;

    @include large {
      display: none;
    }

    button {
      border: none;
      color: $color-white;
      background-color: $color-white;

      &:focus-visible {
        /* Draw the focus when :focus-visible is supported */
        outline: 2px solid $color-sapphire;
        outline-offset: 2px;
      }

      @supports not selector(:focus-visible) {
        &:focus {
          /* Fallback for browsers without :focus-visible support */
          outline: 2px solid $color-sapphire;
          outline-offset: 2px;
        }
      }
    }
  }

  .accountHeading {
    display: flex;
    flex: 0 0 auto;
    align-items: center;

    a {
      display: flex;
      &:focus-visible {
        /* Draw the focus when :focus-visible is supported */
        outline: 2px solid $color-sapphire;
        outline-offset: 2px;
      }

      @supports not selector(:focus-visible) {
        &:focus {
          /* Fallback for browsers without :focus-visible support */
          outline: 2px solid $color-sapphire;
          outline-offset: 2px;
        }
      }
    }
  }

  ul {
    li {
      margin-left: 0.5rem;
      list-style-type: none;

      a {
        display: block;
        text-decoration: none;
      }
    }

    @include small {
      max-width: 24rem;
    }

    @include notLarge {
      position: absolute;
      top: calc(5.5rem - 1px);
      right: -100%;
      height: 100vh;
      width: 100vw;
      padding-top: 1rem;
      border-top: 1px solid $color-gothic;
      z-index: $z-index-hamburger;
      opacity: 0;
      background-color: $color-lightblack;
      transition: $transition;

      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;

      &.open {
        opacity: 0.9;
        right: 0;
      }

      li {
        padding: 1rem;

        a {
          width: max-content;
          padding-bottom: 0.5rem;
          border-bottom: 4px solid $color-lightblack;
          color: $color-white;
          font-size: $font-large;
          font-weight: $font-semibold;

          &:hover,
          &:focus,
          &.active {
            border-bottom: 4px solid $color-primary;
          }

          &.active {
            pointer-events: none;
            text-decoration: none;
          }
        }
      }
    }

    @include large {
      display: flex;
      max-width: unset;
      padding-left: 0;
      border-top: 1px solid $color-lightgray;
      flex-flow: row wrap;
      flex-grow: 1;

      .close {
        display: none;
      }

      a {
        padding: 1rem;
        padding-bottom: calc(1rem - 4px);

        color: $color-dark-text;
        font-size: $font-default;
        font-weight: normal;

        &:hover,
        &:focus,
        &.active {
          border-bottom: 4px solid $color-primary;
        }

        &.active {
          color: $color-black;
          font-weight: $font-semibold;
          pointer-events: none;
          text-decoration: none;
        }
      }
    }
  }
}

.navClosed {
  @include notLarge {
    ul {
      visibility: hidden;
    }
  }
}
