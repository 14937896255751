@import '../constants';
@import '../mixins';

.dropdownOption {
  cursor: pointer;
  padding: 0.75rem;

  &:hover,
  &:focus {
    background-color: $color-lightgray;
  }

  &:focus {
    outline: 2px solid var(--idme-focus-outline-color, var(--idme-color-sapphire, #1F5DB6));
    outline-offset: 0px;
  }
}