@import '../../constants';
@import '../../common';

.profile {
  @extend %margin-right-medium;
}

.top {
  @extend %thin-border-top;

  padding: 1.5rem 1.5rem 0 1.5rem;
  grid-area: profile-top;
  width: 100%;
  background-color: $color-white;

  @include medium {
    padding: 1.625rem 1.625rem 0 1.625rem;
  }

  @include large {
    padding: 2rem 2rem 0 2rem;
  }
}

.title {
  @extend %padding-medium;

  display: flex;
  align-items: center;

  img {
    @extend %margin-right-medium;

    width: auto;
    vertical-align: bottom;

    max-height: 1.6875rem;

    @include medium {
      max-height: 1.875rem;
    }

    @include large {
      max-height: 2.25rem;
    }
  }
}

.content {
  @include medium {
    display: flex;
  }
}

.bigFirstName {
  @extend %font-hero;
}
.bigLastName {
  @extend %font-hero;
  margin-bottom: 1rem;
}

.right {
  @extend %padding-medium;

  flex: 1 1 auto;
  word-break: break-word;
}

.memberSince {
  margin-bottom: 1rem;
  font-weight: $font-semibold;
}

.email {
  a {
    @extend %link;
    display: block;
    margin-top: 0.5rem;
  }
}

.bottom {
  @extend %thin-border-bottom;
  @extend %padding-medium;

  grid-area: profile-bot;
  margin-bottom: 1rem;
  background-color: $color-white;

}
