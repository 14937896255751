@import '../../constants';
@import '../../common';

.profilesWrapper {
  max-width: 72rem;
  width: 100%;
  margin-bottom: 1rem;

  display: flex;
  align-items: top;
  justify-content: space-between;
  gap: 1rem;

  flex-wrap: wrap;

  @include medium {
    flex-wrap: nowrap;
  }
}
