@import '../../constants';
@import '../../common';

.overlay {
  @extend %overlay;
}

.modal {
  @extend %modal;

  text-align: center;

  @include medium {
    top: 8rem;
    max-width: 30rem;
    max-height: calc(100vh - 10rem);
    border-radius: 0.5rem;
    box-shadow: $box-shadow;
  }
}

.header {
  @extend %modal-delete-header;
}

.body {
  @extend %padding-large;

  h4 {
    margin: 1rem auto;
    padding: 0 2rem;
  }

  p {
    padding: 0 3rem;
  }

  .buttons {
    @extend %modal-delete-buttons;
  }
}
