@import '../../common';
@import '../../constants';

.id {
  @extend %id;

  @extend %id-card;
}

.idBody {
  @extend %padding-medium;

  text-align: center;

  a {
    @extend %link;
  }
}
