@import '../../constants';
@import '../../common';

.table {
  @extend %table;
  margin-bottom: 0;

  .bold {
    font-weight: $font-semibold;
  }

  tr > td.none {
    text-align: center;
  }

  tbody > tr:not(:last-child) {
    border-bottom: 1px solid $color-lightgray;
  }

  tr {
    border-bottom: 1px solid $color-lightgray;
    padding-left: 0;
  }

  tr > td > div.bold {
    padding-bottom: 0.5rem;
  }

  th,
  td {
    text-align: left;
    @include large {
      padding: 1.5rem 0.5rem;
    }
  }

  idme-button {
    vertical-align: super;
  }
}
