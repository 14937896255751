@import '../constants';

.fullPage {
  width: 100%;
  height: 100vh;

  text-align: center;

  img {
    position: fixed;
    top: 30vh;
    left: calc(50vw - 3rem);
    width: 6rem;
    height: auto;
  }
}
