@import '../../common';
@import '../../constants';

.wrapper {
  width: 100%;
}

.homepageTitle {
  font-weight: $font-semibold;
  padding-bottom: 1rem;
}

.homepage {
  display: flex;
  justify-content: space-between;

  padding-left: 0.25rem;
  padding-bottom: 1.75rem;
  border-bottom: 1px solid $color-lightgray;
}

.radios {
  display: flex;
  flex-wrap: wrap;
  gap: 0.75rem;

  label:not(:last-child) {
    @extend %margin-right-medium;
  }
}

.subscriptionsTop {
  padding-top: 1.5rem;
  padding-bottom: 1rem;
  display: flex;
  flex-wrap: wrap;
  gap: 0.75rem;

  justify-content: space-between;

  > :first-child {
    font-weight: $font-semibold;
  }
}

.subscriptionsList {
  padding-left: 0.25rem;

  > :last-child {
    margin-bottom: 0;
  }
}

.buttons {
  display: flex;
  flex-direction: row-reverse;

  .buttonWrapper {
    margin-left: 1rem;
  }
}
