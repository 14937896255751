@import '../../constants';
@import '../../common';

.cashback {
  @extend %padding-large;
  @extend %thin-border;

  flex: 1;
  margin-bottom: 1rem;
  background-color: $color-white;

  @include medium {
    margin-right: 1rem;
  }

  a {
    @extend %link;
  }
}

.title {
  @extend %padding-medium;

  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;

  img {
    @extend %margin-right-medium;

    border-radius: 1rem;
    height: 2rem;
    width: auto;
    vertical-align: bottom;
  }
}

.total {
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-bottom: 1rem;

  .subtitle {
    margin-bottom: 0.25rem;
  }

  .money {
    @extend %font-hero;
    @extend %font-medium;

    margin-bottom: 1rem;

    color: $color-primary;
    letter-spacing: $font-close;
  }
}

.manage {
  margin: 0 auto;
  text-align: center;
}
