@import '../../constants';
@import '../../common';

.overlay {
  @extend %overlay;
}

.modal {
  @extend %modal;

  text-align: center;

  @include medium {
    top: 8rem;
    max-width: 30rem;
    max-height: calc(100vh - 10rem);
    border-radius: 0.5rem;
    box-shadow: $box-shadow;
  }
}

.header {
  @extend %modal-delete-header;
}

.body {
  @extend %padding-large;

  h4 {
    margin: 0 auto 3rem;
    padding: 0 2rem;
  }

  .logo {
    @extend %padding-small;

    margin: 1rem auto;
    width: 3rem;
    height: 3rem;
    border: 1px solid $color-lightgray;
    border-radius: 1.5rem;

    img {
      display: block;
      margin: 0 auto;
      max-height: 100%;
    }

    @media only screen and (min-width: 960px) {
      .apple {
        padding-top: 0.1875rem;
      }
    }

    @media only screen and (max-width: 960px) {
      .apple {
        padding-bottom: 0.1rem;
      }
    }
  }

  .name {
    margin: 1rem auto 0.5rem;
    font-weight: $font-semibold;
    color: $color-secondary;
  }

  .email {
    margin-bottom: 3rem;
  }

  .buttons {
    @extend %modal-delete-buttons;

    border-top: 1px solid $color-lightgray;
  }
}
