@import '../constants';
@import '../mixins';

.button {
  border: none;
  border-radius: 50%;
  background-color: $color-darkwhite;
  cursor: pointer;

  padding: 0.5625rem;

  @include medium {
    padding: 0.625rem;
  }

  @include large {
    padding: 0.75rem;
  }

  img {
    width: 1.25rem;
    height: 1.25rem;
  }
}
