@import '../../constants';
@import '../../common';
@import '../../mixins';

.wrapper {
  @extend %main-security;
}

.form {
  margin: 1rem 0;

  @include medium {
    display: flex;
    flex-flow: row wrap;
  }

  fieldset {
    @include medium {
      margin-right: 2rem;
    }
  }

  input[type='password'] {
    @include small {
      min-width: 20rem;
    }

    &.invalid {
      &:not(:focus) {
        box-shadow: 0 0 2px 2px $color-error;
      }
    }
  }

  .srOnly {
    @extend %sr-only;
  }
}

.error {
  p {
    margin-top: 0;
    margin-bottom: 0;
    color: $color-error;
    font-weight: $font-semibold;
  }
}

.controls {
  margin-top: 1rem;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;

  @include medium {
    flex: 1 1 100%;
    margin-top: 0;
  }

  > * {
    display: inline-block;

    &:not(:first-child) {
      margin-left: 1rem;
    }
  }

  .success {
    @include fadeOut;
    color: $color-primary;
    font-weight: $font-semibold;
  }
}
