@import '../constants';
@import '../common';

.gray {
  @extend %main-background;
}

.content {
  padding: 1rem 0rem 0rem;
  
  @supports (display: grid) {

    @include small {
      padding: 1rem 1rem 0rem;
    }

    @include medium {
      margin: 0 auto;
      padding: 2rem 1rem 1rem;
      display: grid;
      grid-row-gap: 0;
      grid-column-gap: 1rem;

      grid-template-columns: 1fr 1fr;
      grid-template-areas:
        'data        data'
        'profile-top ids'
        'profile-bot logins'
        'bottom      bottom';
    }
  }

  @include large {
    max-width: 74rem;
    padding: 3rem 1rem 1rem;
  }
}

.bottomRow {
  grid-area: bottom;
  display: flex;
  flex-direction: column;

  @include medium {
    flex-flow: row nowrap;
    justify-content: space-between;
  }
}
