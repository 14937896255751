@import '../../common';
@import '../../constants';

.wrapper {
  width: 100%;
}

.add {
  margin-top: 1rem;
  text-align: left;
}
