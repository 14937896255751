@import '../../constants';
@import '../../common';
@import '../../mixins';

.wrapper {
  text-align: left;

  h4 {
    @extend %card-title;
    margin-top: 2rem;
  }

  .secondaryInput {
    margin-left: 1.5rem;
  }

  .semibold {
    font-weight: $font-semibold;
  }
}

.inputGroup {
  margin-top: 1rem;
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;
  gap: 1rem;
}

.halfWidth {
  width: 100%;
  float: left;
}
