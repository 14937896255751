@import '../../constants';
@import '../../common';

.downloadStatus {
  display: flex;
  row-gap: 1.5rem;
  flex-direction: row;
  flex-wrap: wrap;
}
.statusRow {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.statusColumn {
  flex-basis: 100%;
}
