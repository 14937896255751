@import '../../constants';
@import '../../common';

.tile {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  border: 1px solid $color-secondary-hover;
  border-radius: 0.5rem;
  position: relative;
  text-align: left;
  margin-bottom: 1rem;

  .tileBody {
    margin: 1rem 1rem 0 1rem;
    flex-basis: 100%;
    display: flex;
    align-items: flex-start;

    .icon {
      height: 2rem;
      width: 2rem;
      stroke: $color-dark-text;
    }
    .content {
      display: flex;
      flex-direction: column;
      margin-left: 1rem;
    }
    .heading {
      @extend %font-medium;

      color: $color-secondary;
      font-weight: $font-semibold;
      margin: 0.5rem 0;
    }
    .body {
      margin: 0.5rem 0;
    }
  }
  .cta {
    border-radius: 0.5rem 0 0.5rem 0;
    background-color: $color-secondary-hover;
    color: $color-white;
    padding: 0.75rem 1rem;
    margin: 0 -1px -1px 0;
    font-weight: $font-semibold;
    text-decoration: none;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }
}
