@import '../../constants';
@import '../../common';

.wrapper {
  grid-area: logins;
  margin-bottom: 1rem;
  padding: 0.75rem 1.5rem;

  @include medium {
    padding: 0.8125rem 1.625rem;
  }

  @include large {
    padding: 1rem 2rem;
  }

  @extend %thin-border-bottom;
  background-color: $color-white;
}

.title {
  display: flex;
  justify-content: space-between;
  padding: 0.75rem 0.75rem 0;

  @include medium {
    padding: 0.8125rem 0.8125rem 0;
  }

  @include large {
    padding: 1rem 1rem 0;
  }

  h3 {
    display: flex;
    align-items: center;
  }

  img {
    @extend %margin-right-medium;

    width: auto;
    vertical-align: bottom;

    max-height: 1.5rem;

    @include medium {
      max-height: 1.625rem;
    }

    @include large {
      max-height: 2rem;
    }
  }
}

.logins {
  margin-bottom: 1rem;
  padding-top: 1rem;

  display: flex;
  justify-content: center;
}

.login {
  @extend %padding-medium;

  width: 3rem;
  height: 3rem;
  margin: 0 0.5rem;
  border-radius: 50%;
  border: 1px solid $color-lightgray;

  width: 4rem;
  height: 4rem;

  img {
    height: 2rem;
    width: 2rem;
  }

  @media only screen and (min-width: 960px) {
    .apple {
      padding-bottom: 0.1875rem;
    }
  }

  @media only screen and (max-width: 960px) {
    .apple {
      padding-bottom: 0.125rem;
    }
  }
}

.manage {
  text-align: center;

  padding-bottom: 1rem;

  a {
    @extend %link;
  }
}
