@import '../../constants';
@import '../../common';

.card {
  > p {
    text-align: center;
  }
}

.tabs {
  display: flex;
  flex-flow: row wrap;
  border-bottom: 1px solid $color-gray;
  margin-bottom: 1rem;

  button {
    padding-bottom: 1rem;
    border: none;
    background-color: inherit;
    cursor: pointer;

    &.active,
    &:focus {
      border-bottom: 3px solid $color-primary;
      padding-bottom: calc(1rem - 3px);
    }

    &.active {
      font-weight: $font-semibold;
    }
  }

  button:not(:last-child) {
    margin-right: 0.5rem;

    @include medium {
      margin-right: 2rem;
    }
  }
}
