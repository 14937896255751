@import '../constants';
@import '../mixins';

.dropdownArea {
  position: relative;

  button {
    border: 0;
    border-radius: 3rem;
    padding: 0rem 0.75rem 0.75rem 0.75rem;
    color: $color-darkgray;
    font-weight: $font-bold;
    width: 3rem;
    height: 3rem;
    font-size: 1.375rem;

    &:active, &:hover, &:focus {
      background-color: $color-lightgray;
    }

    &.clicked {
      background-color: $color-darkgray;
      color: $color-white;
    }

    &:focus {
      outline: 2px solid var(--idme-focus-outline-color, var(--idme-color-sapphire, #1F5DB6));
      outline-offset: 2px;
    }
  }
}

.dropdown {
  position: absolute;
  right: 0;

  width: 14rem;
  padding: 1rem 0;
  border: 1px solid $color-gray;
  border-radius: 20px;
  background-color: $color-white;
  z-index: 10;
}
