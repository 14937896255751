@import '../../constants';
@import '../../common';

.title {
  @extend %card-title;
}

.table {
  @extend %table;
  margin-top: 1rem;

  td[title='WEBSITE NAME:'] {
    :first-child {
      @extend %h3;
    }
  }
}

td.revoke {
  padding: 1.5rem 1rem;

  button {
    @extend %link;
    display: block;
    margin: 0 auto;
    color: $color-error;
    background-color: inherit;
    border: none;

    &:hover,
    &:focus {
      color: $color-error-hover;
    }
  }
}

.overlay {
  @extend %overlay;
}

.modal {
  @extend %modal;
  background-color: $color-white;

  @include medium {
    @include thin-border;
    margin-bottom: 1rem;
    top: 12rem;
    max-width: 40rem;
    max-height: 30rem;
    box-shadow: $box-shadow;
  }
}
