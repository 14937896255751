@import '../constants';
@import '../common';

.card {
  @extend %font-medium;
  @extend %thin-border;

  border-color: $color-success-secondary;
  margin-bottom: 2rem;

  padding: 1.25rem 3.75rem;
  background-color: $color-success-background;

  position: relative;

  .iconLeft {
    position: absolute;
    top: 1.25rem;
    left: 1.25rem;
  }
  .iconRight {
    position: absolute;
    top: 1.25rem;
    right: 1.25rem;
  }
}
