@import '../../constants';
@import '../../common';

.wrapper {
  // IE 11 fix: https://github.com/philipwalton/flexbugs#flexbug-7
  @extend %main-background;
  @include medium {
    flex: 1 1 auto;
  }
}

.ids {
  @extend %thin-border;
  padding: 2.5rem;
  
  background-color: $color-white;
  max-width: 72rem;

  @include medium {
    margin: 1.625rem;
  }

  @include large {
    margin: 2rem;
    padding: 3rem;
  }
}

.title {
  @extend %card-title;
}

.subtitle {
  margin: 1.5rem 0 1.5rem 0;
  padding-bottom: 0.75rem;
  border-bottom: 1px solid $color-lightgray;

  @include medium {
    margin: 1.625rem 0 2.5rem 0;
    padding-bottom: 0.8125rem;
  }

  @include large {
    margin: 2rem 0 3rem 0;
    padding-bottom: 1rem;
  }

  button:focus > img {
    outline: 1px solid $color-primary;
  }

  img {
    @extend %margin-right-medium;

    height: 0, 75rem;
    width: auto;

    transition: $transition;

    &.collapsed {
      transform: rotate(-90deg);
    }
  }
}

.error {
  color: $color-error;
}

.idList {
  @include medium {
    display: flex;
    margin: 0 -0.25rem;
    flex-flow: row wrap;
    justify-content: left;
  }
}
