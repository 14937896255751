@import '../constants';
@import '../common';
@import '../mixins';

.alert {
  @extend %font-default;
  @extend %padding-small;

  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: $color-gothic;
  color: $color-white;
  font-family: 'Open Sans';

  img {
    margin-right: 0.5rem;
  }

  a,
  button {
    @extend %font-default;

    color: $color-white;
  }

  .new {
    display: inline-block;
    font-weight: $font-bold;
    background-color: #08833d;
    border-radius: 4px;
    padding-right: 4px;
    padding-left: 4px;
  }

  .message {
    flex: 0 1 auto;
  }

  .cta {
    display: flex;
    flex: 0 1 20rem;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-left: 0.5rem;

    border: 1px solid $color-white;
    border-radius: 0.25rem;
    padding: 0.25rem;

    background-color: $color-gothic;
    color: $color-white;
    font-weight: 700;
    text-decoration: none;

    &:focus,
    &:hover {
      text-decoration: underline;
      box-shadow: 0 0 4px 0 $color-white;
    }

    &:disabled {
      cursor: not-allowed;
    }

    @include small {
      flex: 0 1 auto;
    }
  }

  .ctas {
    display: flex;
    flex-flow: row wrap;
    padding: 0.5rem 1rem;

    .ctaWrap {
      align-items: center;
      display: flex;
      flex-flow: column nowrap;
    }

    .title {
      font-weight: $font-bold;
      margin-bottom: 0.25rem;
    }

    a {
      font-weight: $font-bold;
    }

    .sep {
      background: $color-white;
      border: 0;
      display: block;
      margin: 0 1rem;
      width: 1px;
    }
  }

  @media screen and (max-width: $large) {
    flex-flow: column nowrap;
    font-size: $font-medium;

    .ctas {
      width: 100%;
      .policy {
        width: 48%;
        text-align: center;
        justify-content: flex-start;
      }
      .promise {
        text-align: center;
        width: 48%;
        justify-content: flex-end;
      }
      a {
        font-weight: $font-bold;
        font-size: $font-medium;
      }
    }
  }

  @media screen and (max-width: $medium) {
    flex-flow: column nowrap;
    font-size: $font-medium;

    .ctas {
      width: 100%;
      .policy {
        width: 47%;
        text-align: center;
        justify-content: flex-start;
      }
      .promise {
        text-align: center;
        width: 47%;
        justify-content: flex-end;
      }
      a {
        font-weight: $font-bold;
        font-size: $font-medium;
      }
    }
  }

  @media screen and (max-width: $small) {
    .message {
      text-align: center;
    }

    .ctas {
      width: 100%;
      padding: 0;
      font-size: $font-medium;

      .policy {
        text-align: left;
        width: 100%;
      }
      .promise {
        text-align: left;
        width: 100%;
      }
      .sep {
        display: none;
      }
      a {
        font-weight: $font-bold;
        font-size: $font-medium;
      }
    }

    .ctaWrap {
      padding: 0.5rem;
      width: 100%;
    }
  }
}
