@import '../constants';
@import '../common';
@import '../mixins';

.overlay {
  @extend %overlay;
}

.modal {
  @extend %modal;
  padding: 2rem;
  text-align: center;

  @include small {
    padding: 2.5rem 2rem;
  }

  @include medium {
    top: 4rem;
    max-width: 31rem;
    max-height: calc(100vh - 8rem);
    border-radius: 1rem;
    box-shadow: $box-shadow;
  }

  p {
    margin-bottom: 1.5rem;
  }

}

.header {
  text-align: center;
  font-size: $font-medium;
  display: block;
  margin-bottom: 1.5rem;
  padding: 0rem 2.75rem;
  font-weight: $font-semibold;
  word-wrap: break-word;
}

.body {
  margin-bottom: 2rem;
}

.closeIcon {
  cursor: pointer;
  position: absolute;
  right: 2rem;
  top: 2rem;
}

.disabledIcon {
  cursor: auto;
}

.wideModal {
  @include medium {
    max-width: 45rem;
  }
}
