.main {
  margin: 0 auto;
  max-width: 72rem;
  min-height: 80vh;
  padding: 2rem 0;

  img {
    display: block;
    margin: 0 auto;
  }
}
