@import '../../constants';
@import '../../common';

.card {
  width: 100%;
  margin: 0;

  .subtitle {
    font-size: 1rem;
    font-weight: $font-semibold;
  }

  .separator {
    border: 0;
    border-top: 1px solid $color-lightgray;
    margin: 1.5rem 0;
  }

  section {
    display: flex;
    align-items: top;
    justify-content: space-between;
  }

  p {
    margin-top: 1rem;
    margin-bottom: 0rem;
  }

  .noMarginBottom {
    margin-bottom: 0;
  }

  .noMarginTop {
    margin-top: 0;
  }

  .editButton {
    margin-left: auto;
  }

  .emailDiv {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    flex-wrap: wrap;
    margin-top: 1rem;
    margin-bottom: 0rem;
  }

  .email {
    word-break: break-all;
  }
}
