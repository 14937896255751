@import '../constants';
@import '../common';

.buttonPrimary {
  @extend %button;

  border: none;
  background-color: $color-secondary;
  color: $color-white;

  &:hover,
  &:focus {
    background-color: $color-secondary-hover;
  }
}
