@import '../../constants';
@import '../../common';
@import '../../mixins';

.tile {
  cursor: pointer;
  display: flex;
  flex-direction: column;

  border: 1px solid $color-secondary-hover;
  border-radius: 0.5rem;
  position: relative;
  text-align: left;
  margin-bottom: 1rem;

  .tileBody {
    margin: 1rem;
    flex-basis: 100%;
    display: flex;
    align-items: flex-start;

    .content {
      display: flex;
      flex-direction: column;
    }

    .heading {
      color: $color-secondary;
      font-size: $font-default;
      font-weight: $font-semibold;
    }

    p {
      margin-top: 0.5rem;
      margin-bottom: 0rem;
      font-size: 0.875rem;
    }

  }
}

.disabledTile {
  cursor: auto;
  border: 1px solid $color-darkgray;
  background-color: $color-darkwhite;

  .tileBody {
    .disabledHeading {
      color: $color-lightblack;
    }
  }
}
