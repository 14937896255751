@import '../../constants';
@import '../../common';

.preferences {
  @extend %padding-large;
  @extend %thin-border;

  flex: 1;
  margin-bottom: 1rem;
  background-color: $color-white;

  @include medium {
    margin-right: 1rem;
  }

  a {
    @extend %link;
  }
}

.title {
  @extend %padding-medium;

  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;

  img {
    @extend %margin-right-medium;

    height: 2rem;
    width: auto;
    vertical-align: bottom;
  }
}

.detail {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: 1rem;

  @include medium {
    margin-bottom: 2rem;
    display: inline-flex;
    width: 50%;

    &:nth-child(2) {
      border-right: 1px solid $color-lightgray;
    }
  }

  img {
    height: 2rem;
    width: auto;
    margin-bottom: 0.5rem;

    @media screen and (max-width: $small) {
      display: none;
    }
  }

  span {
    margin-bottom: 0.5rem;
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      width: 100%;
    }
  }
}

.manage {
  text-align: center;
}
