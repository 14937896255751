@import '../../constants';
@import '../../common';

.body {
  position: relative;

  h3 {
    img {
      @extend %margin-right-medium;

      height: 2rem;
      width: auto;
    }
    margin: 2rem 0;
  }

  p {
    margin-bottom: 2rem;
  }

  ul {
    column-count: 2;
    list-style: none;
    text-align: left;

    li {
      margin-bottom: 1rem;
      padding-left: 1.5rem;

      &:before {
        position: absolute;
        content: '•';
        background-size: cover;
        height: 1rem;
        width: 1rem;
        margin: 0.25rem 0 0 -1.5rem;

        @include medium {
          margin: 0 0 0 -0.75rem;
        }
      }
    }
  }
}
