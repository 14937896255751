@import '../constants';

.label {
  display: block;
  position: relative;
  margin-bottom: 1rem;
  cursor: pointer;

  &:hover {
    input ~ .checkbox {
      background-color: $color-lightgray;
    }
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked ~ .checkbox {
      background-color: $color-primary;
      border: none;

      &:after {
        display: block;
      }
    }

    &:focus ~ .checkbox {
      box-shadow: 0 0 2px 1px $color-primary-hover;
    }
  }

  .checkbox {
    position: absolute;
    top: 0;
    left: 0;
    height: 18px;
    width: 18px;
    background-color: $color-white;
    border: 2px solid $color-gray;
    border-radius: 0.25rem;

    &:after {
      content: '';
      position: absolute;
      display: none;

      left: 6px;
      top: 2px;
      width: 6px;
      height: 11px;
      border: solid $color-white;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }
  }
}

.children {
  margin-left: 2rem;
}
