@import '../../constants';
@import '../../common';

.validation {
  padding: 1rem;

  @include medium {
    margin-bottom: 1rem;
  }

  h3 {
    @extend %font-default;

    margin-bottom: 1rem;
  }

  li {
    @extend %font-default;

    list-style: none;
    margin-bottom: 0.25rem;

    span {
      display: inline-block;
      width: 1rem;
      font-weight: $font-semibold;
    }
  }

  p {
    @extend %font-small;

    margin-bottom: 0;
    color: $color-secondary;
    font-weight: $font-semibold;
  }
}

.red {
  color: $color-error;
}

.green {
  color: $color-primary;
}
