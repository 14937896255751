@import '../constants';
@import '../common';

.buttonErrorSolid {
  @extend %button;

  background-color: $color-error;
  color: $color-white;

  &:hover,
  &:focus {
    box-shadow: 0 0 4px 0 $color-error;
  }
}
