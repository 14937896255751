@import '../common';

.emailConfirm {
  @extend %padding-large;

  margin: 1rem auto;
  max-width: 40rem;
  border-radius: 0.5rem;
  background-color: $color-white;
  text-align: center;

  @media screen and (min-width: 300px) {
    margin: 2rem auto;
  }

  img {
    margin-bottom: 1rem;
  }

  h2 {
    font-weight: $font-normal;
    margin-bottom: 2rem;
  }

  h3 {
    @extend %font-default;

    margin-bottom: 0.25rem;
  }

  p {
    margin-top: 0.25rem;
    padding: 0 1rem;
  }

  a {
    @extend %button;
    display: inline-block;
    margin: 1rem auto;
    background-color: $color-secondary;
    color: $color-white;
    text-decoration: none;

    &:link,
    &:visited {
      color: $color-white;
    }

    &:active,
    &:focus,
    &:hover {
      background-color: $color-secondary-hover;
    }
  }
}
