@import '../constants';
@import '../common';

.buttonLink {
  @extend %link;
  border: none;
  padding: 0.25rem 0;
  background-color: inherit;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}
