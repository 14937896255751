@import '../constants';
@import '../common';

.card {
  @extend %thin-border;
  padding: 2.5rem;
  margin-bottom: 1rem;
  background-color: $color-white;

  max-width: 72rem;

  .title {
    border-bottom: 1px solid $color-lightgray;
    padding-bottom: 1rem;
    display: flex;
    align-items: center;

    .icon {
      height: 2rem;
      margin-right: 0.5rem;

      img {
        height: 2rem;
      }
    }
    margin-bottom: 1.5rem;
  }
}
