@import '../constants';
@import '../common';

.counter {
  padding: 0.375rem 0.5rem;

  border-radius: 0.25rem;
  color: $color-white;
  background-color: $color-secondary;

  text-align: center;
  font-weight: $font-bold;
  display: flex;
  align-items: center;
}

.success {
  color: $color-white;
  background-color: $color-success-secondary;
}

.info {
  @extend %thin-border;
  color: $color-dark-text;
  background-color: $color-white;
}
