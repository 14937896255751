@import '../../constants';
@import '../../common';
@import '../../mixins';

.background {
  @extend %padding-medium;

  font-weight: $font-semibold;
  background: $color-secondary-background;
  display: flex;
  flex-direction: column;
  align-items: center;
}
