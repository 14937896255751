@import '../../constants';
@import '../../common';

.card {
  @extend %card;
  display: flex;
  flex-direction: column;

  a {
    @extend %link;
  }
}

.fieldDetailText {
  @extend %font-small;

  margin-top: -1rem;
}

.buttons {
  @extend %modal-delete-buttons;
  justify-content: left;

  button {
    margin-right: 2rem;
    max-width: 7rem;
  }
}

.spinner {
  display: block;
  margin: 2rem auto;
}
