@import '../../common';
@import '../../constants';

.email {
  position: relative;
  border-bottom: 1px solid $color-lightgray;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  text-align: left;

  div {
    align-self: center;
  }

  justify-content: space-between;
  display: grid;
  gap: 1rem;

  grid-template-columns: 7fr 1fr;

  @include large {
    grid-template-columns: 5fr 1fr;
  }
}

.flexArea {
  display: block;

  @include medium {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    justify-content: space-between;
  }
}

.buttonContainer {
  float: right;
}

.emailKindDiv {
  width: fit-content;
  font-weight: $font-semibold;
  margin-top: 0.5rem;

  @include medium {
    width: 9rem;
    margin-top: unset;
  }

  @include large {
    width: 12.5rem;
  }
}

.workProfileName {
  margin-top: 0.25rem;
}

.emailStatusDiv {
  white-space: nowrap;
  font-weight: $font-semibold;
  margin-top: 0.5rem;

  @include medium {
    width: 8.5rem;
    margin-top: unset;
  }
}

.address {
  flex-shrink: 0;
  font-weight: $font-semibold;
  word-break: break-all;

  flex-basis: 100%;

  @include medium {
    width: 17rem;
    flex-basis: auto;
  }

  @include large {
    width: 22rem;
  }
}
