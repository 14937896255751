@import '../../constants';
@import '../../common';

.idOuter {
  @extend %id-card;
}

.idCollapsed {
  all: inherit;
  width: 100%;
  border-radius: 1.25rem;
  margin-bottom: 2rem;
  padding: 0;
  cursor: pointer;
  box-shadow: 0 5px 15px 1px $color-lightgray;
  border: none;
}

.idExpanded {
  @extend %id;
}

.idBody {
  display: flex;
  flex-flow: row wrap;
  padding: 0.75rem;

  @include medium {
    padding: 1.25rem;
  }

  @include large {
    padding: 1.5rem;
  }
}

.idSeparator {
  border: 0;
  border-top: 1px solid $color-lightgray;
  margin: 1rem 0;
}

.idLinks {
  display: flex;
  justify-content: center;

  .item {
    &:not(:first-child) {
      border-left: 1px solid $color-lightgray;
      margin-left: 0.5rem;
      padding-left: 0.5rem;
    }
  }
}

.left, .right, .expired {
  padding-bottom: 0.75rem;

  @include medium {
    padding-bottom: 0.8125rem;
  }

  @include large {
    padding-bottom: 1rem;
  }
}

.left {
  flex: 0 1 60%;
  margin-right: 4%;

  font-weight: $font-bold;
}

.right {
  flex: 0 1 36%;

  > :first-child {
    font-weight: $font-bold;
  }

  .VERIFIED,
  .APPROVED {
    color: $color-primary;
  }

  .DENIED {
    color: $color-error;
  }
}

.expired {
  flex: 0 1 36%;

  > :first-child {
    color: $color-error;
    font-weight: $font-bold;
  }

  > :nth-child(3) {
    color: $color-primary;
    font-weight: $font-bold;
  }
}

.bottom {
  flex: 0 1 100%;
}

%deats {
  border-top: 1px solid $color-lightgray;
  border-bottom: 1px solid $color-lightgray;
  padding: 1rem;

  @include medium {
    padding: 1.5rem;
  }
}

.detailsWrapper {
  @extend %deats;

  .details {
    display: flex;
    flex-flow: row wrap;

    .group_title {
      @extend %margin-right-medium;

      font-weight: $font-bold;
      flex: 0 0 auto;
      min-height: 3.5rem;
      width: 100%;
    }

    .sub_title {
      @extend %margin-right-medium;

      flex: 0 0 auto;
      max-width: 100%;
      min-height: 3.5rem;
    }

    .dt {
      font-weight: $font-bold;
      margin-bottom: 0.25rem;
    }

    .dd {
      margin-bottom: 1rem;
    }

    .title {
      font-weight: $font-bold;
      flex: 0 0 auto;
      border-bottom: 1px solid black;
      width: 100%;
      padding-bottom: 1rem;
      padding-top: 1rem;
      margin-bottom: 1rem;
      margin-top: 1rem;
    }

    .list_item {
      flex: 0 0 auto;
      width: 100%;
    }
  }
}

.cta {
  padding: 0 0 1rem;

  a {
    @extend %link-button;
    display: block;
    margin: 0 auto;
    max-width: fit-content;
  }
}

.reverify {
  margin-top: 1rem;
  padding: 1rem 0;

  a {
    @extend %link-button-secondary;
    display: block;
    margin: 0 auto;
    max-width: fit-content;
  }
}

.expiredDetails {
  @extend %deats;

  padding-bottom: 1.5rem;

  @include medium {
    padding-bottom: 1.625rem;
  }

  @include large {
    padding-bottom: 2rem;
  }

  a {
    @extend %link-button;
    display: block;
    margin: 0 auto;
    max-width: fit-content;
  }
}

.button {
  @extend %padding-small;

  button {
    display: block;
    margin: 0 auto;

    &:active,
    &:focus,
    &:hover {
      text-decoration: none;
    }
  }
}

.idExpandBar {
  @extend %padding-small;

  text-align: center;
}
