// Grays, Whites & Blacks
$color-black: #000;
$color-lightblack: #343334;
$color-darkgray: #757575;
$color-gray: #949494;
$color-lightgray: #dfdfdf;
$color-darkwhite: #f5f5f5;
$color-white: #fff;
$color-gunpowder-25: rgba(127, 127, 127, 0.25);

// Main Colors
$color-primary: #1d9f61;
$color-primary-hover: #3ac679;
$color-primary-visited: #4eb8a4;

$color-secondary: #266aca;
$color-secondary-hover: #004f93;
$color-secondary-visited: #0071bc;
$color-secondary-background: #f2faff;
$color-sapphire: #1f5db6;

$color-warning: #f5a623;
$color-warning-background: #fffbf0;

$color-error: #e11538;
$color-error-hover: #ea4335;
$color-error-background: #ffebeb;
$color-error-secondary: #ba0000;

$color-dark-text: #2e3f51;
$color-light-text: #6d7278;

$color-success-background: #edf8f3;
$color-success-secondary: #08833d;

$color-info-background: #ecf9ff;

// Special colors
$color-windbreaker: #44a495;
$color-gothic: #335f88;

// boxshadow
$box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.2);

// breakpoints
$small: 500px;
$medium: 780px;
$large: 1000px;

// fonts
$font-default: 1rem;
$font-medium: 1.25rem;
$font-large: 1.5rem;

// NOTE: if any additional font weights are desired, the google font import needs to be updated
// $font-thin: 100;
// $font-light: 200;
// $font-semilight: 300;
$font-normal: 400;
$font-semibold: 600;
$font-bold: 700;
// $font-thick: 900;

$font-close: -0.5px;
$font-wide: 1px;
$font-doublewide: 2px;

// Animation Helpers
$transition: all 0.25s ease-in-out;

// Z-Indices
$z-index-popup: 100;
$z-index-hamburger: 200;

// Symbols
$rsaquo: '\203A';
