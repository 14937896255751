@import '../common';

.wrapper {
  @extend %main-background;
  @extend %side-nav-wrapper;
}

.main {
  margin: 0 auto;
  max-width: 72rem;
  min-height: 80vh;
  padding: 2rem 1rem;
  flex: 1 1 auto;
}
