@import '../common';

.page {
  @extend %main-background;
}

.content {
  max-width: 50rem;
  min-height: 30rem;
  margin: 0 auto;
  padding: 1rem;

  @include small {
    padding-top: 4rem;
  }

  h2 {
    @extend %font-default;

    margin-bottom: 0.5rem;
    text-transform: uppercase;
  }

  h1 {
    margin: 3rem 0;
  }

  section {
    margin-top: 2rem;
  }

  .languageButton {
    width: auto;
    display: inline-block;
  }
}
