@import '../../common';
@import '../../constants';

.accounts {
  @extend %thin-border;
  @extend %main-security;
}

.title {
  @extend %card-title;

  :first-child {
    height: 1.5rem;
    width: auto;
    @extend %margin-right-medium;
    vertical-align: bottom;
  }
}

.table {
  padding: 0.5rem 0.5rem;

  @include large {
    padding: 1rem 0.75rem;
  }
}

.body {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
